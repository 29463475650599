
import { defineComponent } from 'vue';
import { Form, Field, ErrorMessage } from 'vee-validate';
import Swal from 'sweetalert2/dist/sweetalert2.min.js';
import { useRoute } from 'vue-router';
import * as Yup from 'yup';
import ApiService from '@/core/services/ApiService';
import { VueCookieNext } from 'vue-cookie-next';

export default defineComponent({
  name: 'entity-employee-add',
  components: {
    Form,
    Field,
    ErrorMessage,
  },
  data() {
    return {
      load: false,

      employee: {
        employee_father_name: '',
        employee_mother_name: '',
        employee_name: '',
        role: '',
        designation: '',
        employee_dob: '',
        employee_nid: '',
        employee_passport: '',
        employee_driving_license: '',
        employee_mobile: '',
        employee_email: '',
        employee_gender: '',
        employee_religion: '',
        employee_present_address: '',
        employee_present_district: '',
        employee_permanent_address: '',
        employee_permanent_district: '',
        employee_img: '',
      },
      user: {
        password: '',
        password_confirmation: '',
        active_status: '1',
      },
      entityInfos: [],
      entityInfoID: '',
      empDesignation: [] as any,
      entity_type_id: '',
      employee_url: '',
      districts: [],
      subDistricts: [],
      empDistricts: [],
      loading: false,
    };
  },
  async created() {
    await this.getDistrictForEmp();
    await this.getEntity();
    await this.getEmployeeDesignation();
  },
  methods: {
    employeePhoto(e) {
      this.employee.employee_img = e.target.files[0];
      this.employee_url = URL.createObjectURL(this.employee.employee_img);
    },
    async formSubmit() {
      let formData = new FormData();

      formData.set('employee_img', this.employee.employee_img);
      formData.set('entity_id', this.entityInfoID);
      let user_id = VueCookieNext.getCookie('_seip_user');
      formData.set('user_id', user_id.id);
      for (var key in this.employee) {
        formData.set(key, this.employee[key]);
      }
      for (var key in this.user) {
        formData.set(key, this.user[key]);
      }
      this.loading = true;
      await ApiService.post('entity/save_entity_employee', formData)
        .then((response) => {
          this.loading = false;
          if (response.status == 200) {
            Swal.fire({
              title: 'Success!',
              text: response.data.data,
              icon: 'success',
              buttonsStyling: false,
              confirmButtonText: 'Ok',
              customClass: {
                confirmButton: 'btn btn-primary',
              },
            }).then(() => {
              this.employee = {
                employee_father_name: '',
                employee_mother_name: '',
                employee_name: '',
                role: '',
                designation: '',
                employee_dob: '',
                employee_nid: '',
                employee_passport: '',
                employee_driving_license: '',
                employee_mobile: '',
                employee_email: '',
                employee_gender: '',
                employee_religion: '',
                employee_present_address: '',
                employee_present_district: '',
                employee_permanent_address: '',
                employee_permanent_district: '',
                employee_img: '',
              };
              (this.employee_url = ''),
                (this.user = {
                  password: '',
                  password_confirmation: '',
                  active_status: '1',
                });
            });
          } else {
            let err = '';
            for (const field of Object.keys(response.data.errors)) {
              err += response.data.errors[field][0] + '<br>';
            }
            Swal.fire({
              title: 'Please check all the required field',
              html: err,
              icon: 'error',
              buttonsStyling: false,
              confirmButtonText: 'Close',
              customClass: {
                confirmButton: 'btn btn-danger',
              },
            });
          }
        })
        .catch(({ response }) => {
          this.loading = false;
          Swal.fire({
            title: 'Unknown error',
            html: response.data.error,
            icon: 'error',
            buttonsStyling: false,
            confirmButtonText: 'Close',
            customClass: {
              confirmButton: 'btn btn-danger',
            },
          });
          console.log(response);
        });
    },

    async getDistrictForEmp() {
      await ApiService.get('configurations/geo_district/list')
        .then((response) => {
          this.empDistricts = response.data.data;
        })
        .catch(({ response }) => {
          console.log(response);
        });
    },
    async getEntity() {
      this.load = true;
      await ApiService.get('entity/show/' + this.entityInfoID)
        .then((response) => {
          this.load = false;
          this.entityInfos = response.data.data;
          this.entity_type_id = response.data.data.entity_type_id;
        })
        .catch(({ response }) => {
          console.log(response);
        });
    },
    async getEmployeeDesignation() {
      await ApiService.get(
        'entity_type_role/role/allroles?entity_type_id=' + this.entity_type_id
      )
        .then((response) => {
          this.empDesignation = [];
          for (let i = 0; i < response.data.data.role.length; i++) {
            if (
              response.data.data.role[i].id != 4 &&
              response.data.data.role[i].id != 5
            ) {
              this.empDesignation.push(response.data.data.role[i]);
            }
          }
        })
        .catch(({ response }) => {
          console.log(response);
        });
    },
  },
  setup() {
    const AssociationSchema = Yup.object().shape({
      // employeeEmail: Yup.string()
      //   .email("Must be valid email")
      //   .required()
      //   .label("Email"),
      // //presentDistrict: Yup.string().required().label("Present District"),
      // name: Yup.string().required().label("Name"),
      // // designation: Yup.string().required().label("Designation"),
      // gender: Yup.string().required().label("Gender"),
      // religion: Yup.string().required().label("Religion"),
      // father_name: Yup.string().required().label("Father name"),
      // mother_name: Yup.string().required().label("Mother name"),
      // mobile: Yup.number().required().min(11).label("Mobile"),
      // dob: Yup.string().required().label("Date of Birth"),
      // nid: Yup.string().required().label("NID"),
    });
    const route = useRoute();
    const entityInfoID = route.params.id;
    return {
      AssociationSchema,
      entityInfoID,
    };
  },
});
